<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
<!--     退款编号         -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="退款编号:"
                    label-for="refundNo"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.refundNo"
                      name="refundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
<!--      退货编号        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="退货编号:"
                    label-for="returnboundNo"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.returnboundNo"
                      name="returnboundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--      销售编号        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售编号:"
                    label-for="orderNo"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.orderNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--      对账编号        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对账编号:"
                    label-for="statementNo"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.statementNo"
                      name="statementNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>



              <b-col md="4"></b-col>
              <b-col md="4"></b-col>


              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['addTime'])"
                                 :params="['addTime']"
                                 :value="state.condition.addTime"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="退货日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['returnTime'])"
                                 :params="['returnTime']"
                                 :value="state.condition.returnTime"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="客户名称"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="state.condition.channelName"
                    modalName="客户名称"
                    placeholder="请选择客户"
                >
                </xy-input-button>
              </b-col>


              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creator'])"
                    :params="['creatorName','creator']"
                    :value="state.condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="仓库"
                    type="input"
                    v-on:change="fromChildren($event,['warehouseName','warehouseId'])"
                    :params="['warehouseName','warehouseId']"
                    :value="state.condition.warehouseName"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>


      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-salesrefund-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(return_time)="data">
          {{ (data.item.return_time==null)?null:toDate(data.item.return_time) }}
        </template>

        <template #cell(order_no)="data">
          <a target="_blank" :href="toURL(data.item.order_id)">
            {{ data.item.order_no }}
          </a>
          <br>
        </template>

        <template #cell(add_time)="data">


          {{ (data.item.add_time==null)?null:toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel("user", data.item.creator) }}
        </template>

        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">

                <template #cell(productSpecification)="data">
                  {{ data.item.ext.product.specification}}
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(refund_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.refund_id }}]{{data.item.refund_no}}
        </template>



        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->
          <b-link>
            <feather-icon @click="delOrderItem(data.item)"
                icon="XSquareIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-salesrefund-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-salesrefund-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '客户名称'  "
      >
      </company-select>

      <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
      <user-select
          ref="userSelect" :userDepartment="userDepartment" v-else-if="modalName === '创建人' "
      >
      </user-select>

      <store-list
          ref="storeList" v-else-if="modalName === '店铺' "
      >
      </store-list>

    </b-modal>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import salesrefundUseList from './salesrefundUseList'
import salesrefundStore from './salesrefundStore'
import UserSelect from "@/views/apps/user/user-modal/UserSelect";
import CompanySelect from "../company/CompanySelect"
import StoreList from "@/views/apps/store/StoreListSelect";
import XyInputButton from "@/views/components/xy/XyInputButton";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    UserSelect,
    CompanySelect,
    StoreList,
    XyInputButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: '名称', sortable: true },
        { key: 'productSpecification', label: '单箱标准', sortable: true },
        { key: 'salesreturnqty_good', label: '实际退货数量(良品)', sortable: true },
        { key: 'salesreturnqty_bad', label: '实际退货数量(不良品)', sortable: true },
        { key: 'cost_price', label: '退回成本', sortable: true },
        { key: 'product_cost_tax', label: '退回售价', sortable: true },
      ],
      user: {},
      advanced_search_modal: false,
      userDepartment: "sales",
      companyType: '',
      modalName: '',
      channelName: '',
      salesName: '',
      companyName: '',
      storeName: '',
      storeId: 0,
      orderId:0,

    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('salesrefund/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showSingleModal(modalName) {
      if (modalName === '客户名称') {
        this.companyType = 'SalesCustomer'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '客户名称': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.state.condition.channelName = data[0].company_name;
            this.state.condition.channelId = data[0].company_id;
            this.$forceUpdate()
          }
          break
        }
        case '创建人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.state.condition.creatorName = data[0].full_name;
            this.state.condition.creator = data[0].user_id;
            this.$forceUpdate()
          }
          break;
        }

        default: {
          break
        }
      }
    },
  searchByCondition() {
    this.refetchData()
  },
  resetCondition() {
    this.state.condition = {}
    store.commit('salesrefund/updateCondition', this.state.condition)
    this.refetchData();
  }
  },
  setup() {
    // Register module
    if (!store.hasModule('salesrefund')) store.registerModule('salesrefund', salesrefundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesrefund')) store.unregisterModule('salesrefund')
    })

    const change = function (val) {
      if (val.status === 0 || val.status === 1 || val.status === 2) return true
      else return false
    }

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['salesrefund/getCondition']
    })

    const toURL = function (urlId){
        //销售订单
        return "/apps/salesorder/list?order_id=" + urlId
    }
    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


      // UI
    } = salesrefundUseList()

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      change,
      toURL,
      advanced_search,
      fromChildren

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
