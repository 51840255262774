import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function salesrefundUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'refund_id', label: 'ID'},
    { key: 'refund_no', label: '退款编号'},
    //{ key: 'order_id', label: '销售单ID', sortable: true },

    //{ key: 'returnbound_id', label: '退货单ID', sortable: true },
    { key: 'returnbound_no', label: '退货编号', sortable: true },
    //{ key: 'statement_id', label: '对账单ID', sortable: true },
    //{ key: 'channel_id', label: '渠道ID', sortable: true },
    { key: 'channel_name', label: '客户名称' },
    //{ key: 'warehouse_id', label: '入库仓仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称' },
    { key: 'refund_tatal', label: '退款金额' },
    { key: 'return_time', label: '退货日期' },
    //{ key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间' },
    { key: 'creator', label: '创建人' },
    //{ key: 'modify_time', label: '修改时间', sortable: true },
    //{ key: 'updator', label: '修改人', sortable: true },
    { key: 'order_no', label: '销售单编号' },
    { key: 'statement_no', label: '对账单编号' },
       // { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('refund_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['salesrefund/getCondition']}
    store
      .dispatch('salesrefund/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,

        orderNo: condition.orderNo,
        returnboundNo:condition.returnboundNo,
        refundNo: condition.refundNo,
        statementNo: condition.statementNo,
        addTime: condition.addTime,
        returnTime: condition.returnTime,
        warehouse: condition.warehouseId,
        channelId: condition.channelId,
        creator: condition.creator,


        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
